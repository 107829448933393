// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
// import inlineEditor from "application/inlineEditor.js"
// import cmsEditor from "application/cmsEditor.js"

//import * as ActiveStorage from "@rails/activestorage"
//import "channels"

Rails.start()

// document.addEventListener('turbolinks:load', function() {
$(document).ready(function() {

  // if($('.cms-editor').length > 0) {
  //   console.log("cms-editor detected")
  //   cmsEditor.initialize();
  // }

  var controller;

  if ( $("#page-pages-about").length ) {

    if ($(window).width() > 991) {
      var hero = new TimelineMax({onUpdate:updatePercentage});
      var tl = new TimelineMax({onUpdate:updatePercentage});
      var yOffset = 60;
      var heroOffset = -100;
      controller = new ScrollMagic.Controller();

      hero.from(".animate-hero",  1, { y:heroOffset });

      tl.from(".title-0",   0.1, { y:yOffset, opacity: 0 });
      tl.from(".text-0",    0.2, { y:yOffset, opacity: 0 });
      tl.from(".title-1",   0.1, { y:yOffset, opacity: 0 });
      tl.from(".text-1",    0.2, { y:yOffset, opacity: 0 });
      tl.from(".title-2",   0.1, { y:yOffset, opacity: 0 });
      tl.from(".text-2",    0.2, { y:yOffset, opacity: 0 });
      tl.from(".title-3",   0.1, { y:yOffset, opacity: 0 });
      tl.from(".text-3",    0.2, { y:yOffset, opacity: 0 });

      var hero = new ScrollMagic.Scene({
        triggerElement: ".hero",
        duration: "200%"
      })
      .setTween(hero)
      .addTo(controller);

      var about = new ScrollMagic.Scene({
        triggerElement: "#about-text",
        duration: "100%"
      })
      .setTween(tl)
      .addTo(controller);

      function updatePercentage() {
        tl.progress();
        console.log(tl.progress());
      }
    }
  }

  // Scroll magic for header
  function initHeader() {
    controller = new ScrollMagic.Controller();
    var $header = $("#header");
    var $body = $("body");
    var navHeight = $header.outerHeight();
    var windowH = $(window).outerHeight();
    var duration = windowH;
    var scene = new ScrollMagic.Scene({duration: duration})
    // .addIndicators({name: duration})
    .addTo(controller)
    // turn bg gray quickly - rgba change looked weird
    .on("progress", function(e) {
      if (e.progress.toFixed(3) > .1 ) {
        $header.addClass('bg-secondary');
      } else {
        $header.removeClass('bg-secondary');
      }
    });
  }
  initHeader();

  // $('#header').on('click', function() {

  //   if ($())
  // })

  $('#header').on('show.bs.collapse', function () {
    $('.navbar-toggler').addClass('navbar-toggler--open')
    $('#header').addClass('active');
    console.log("clicked menu")
  })

  $('#header').on('hide.bs.collapse', function () {
    $('.navbar-toggler').removeClass('navbar-toggler--open');
    $('#header').removeClass('active');
  })

  if ( $('body#page-partners-index').length ) {
    $('body').scrollspy({target: '#partner-nav'})
  }
});